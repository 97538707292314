<template>
  <el-dialog title="图片上传" :visible.sync="visible" width="1300px" :before-close="handleClose">
    <div class="cropper-content">
      <div class="cropper-box">
        <div class="cropper">
          <vue-cropper ref="cropper" :img="option.img" :outputSize="option.outputSize" :outputType="option.outputType" :info="option.info" :canScale="option.canScale" :autoCrop="option.autoCrop" :autoCropWidth="filmSize.autoCropWidth" :autoCropHeight="filmSize.autoCropHeight" :fixed="option.fixed" :fixedNumber="filmSize.fixedNumber" :full="option.full" :fixedBox="option.fixedBox" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :centerBox="option.centerBox" :height="option.height" :infoTrue="option.infoTrue" :maxImgSize="option.maxImgSize" :enlarge="option.enlarge" :mode="filmSize.mode" @realTime="realTime" @imgLoad="imgLoad">
          </vue-cropper>
        </div>
        <!--底部操作工具按钮-->
        <div class="footer-btn">
          <div class="scope-btn">
            <label class="btn" for="uploads">选择图片</label>
            <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)">
            <el-button size="mini" type="danger" plain icon="el-icon-zoom-in" @click="changeScale(1)">放大</el-button>
            <el-button size="mini" type="danger" plain icon="el-icon-zoom-out" @click="changeScale(-1)">缩小</el-button>
            <el-button size="mini" type="danger" plain @click="rotateLeft">↺ 左旋转</el-button>
            <el-button size="mini" type="danger" plain @click="rotateRight">↻ 右旋转</el-button>
          </div>
          <div class="upload-btn">
            <el-button v-if="file" size="mini" :loading="btn_loading" type="success" @click="uploadImg('blob')">上传图片<i class="el-icon-upload"></i></el-button>
          </div>
        </div>
      </div>
      <!--预览效果图-->
      <div class="show-preview">
        <div :style="previews.div" class="preview">
          <img :src="previews.url" :style="previews.img">
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { VueCropper } from "vue-cropper";
import { uploadImage } from "@/api/shop/goods/goods";
import { mapState } from "vuex";
export default {
  name: "CropperImage",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 同一个页面不同上传图片的类型
    imageType: {
      type: String,
      default: null,
    },
    setOpt: {
      type: Object,
      default: () => new Object(null),
    },
    filmSize: {
      type: Object,
      default: function () {
        return {
          autoCropWidth: 300,
          autoCropHeight: 300,
          fixedNumber: [1, 1],
          mode: "300px 300px",
        };
      },
    },
  },
  data() {
    return {
      previews: {},
      option: {
        img: "", //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        // autoCropWidth: 300,  //默认生成截图框宽度
        // autoCropHeight: 300, //默认生成截图框高度
        fixed: true, //是否开启截图框宽高固定比例
        // fixedNumber: [1, 1], //截图框的宽高比例
        full: false, //false按原比例裁切图片，不失真
        fixedBox: true, //固定截图框大小，不允许改变
        canMove: false, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: false, //上传图片按照原始比例渲染
        centerBox: false, //截图框是否被限制在图片里面
        height: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        // mode: '300px 300px'  //图片默认渲染方式
      },
      file: null,
      btn_loading: false, //上传图片加载
    };
  },
  computed: {
    ...mapState(["hotelInfo"]),
  },
  components: {
    VueCropper,
  },
  methods: {
    //初始化函数
    imgLoad(msg) {
      console.log();
    },
    //图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    //向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    //向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    //实时预览函数
    realTime(data) {
      this.previews = data;
    },
    //选择图片
    selectImg(e) {
      let file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: "图片类型要求：jpeg、jpg、png",
          type: "error",
        });
        return false;
      }
      //转化为blob
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
      };
      this.file = file
      //转化为base64
      reader.readAsDataURL(file);
    },
    //上传图片
    uploadImg(type) {
      if (type === "blob") {
        this.btn_loading = true
        //获取截图的blob数据
        this.$refs.cropper.getCropBlob((file) => {
          let params = new FormData();
          params.append("file", file, "DX.jpg");
          params.append("hotelId", this.hotelInfo.id);
          params.append("keyPrefix", "mall/images");
          uploadImage(params).then(({ success, records }) => {
            this.btn_loading = false
            if (!success) return;
            this.$emit("setImageInfo", records, file, this.imageType,this.file );
            this.$message({ message: "上传成功", type: "success" });
          });
        });
      }
    },
    handleClose() {
      this.$emit("closeUploadImg");
    },
  },
  watch: {
    setOpt(newVal, oldVal) {
      Object.assign(this.option, newVal);
    },
  },
};
</script>
<style scoped lang="scss">
.cropper-content {
  display: flex;
  padding-left: 20px;
  display: -webkit-flex;
  justify-content: flex-end;
  .cropper-box {
    flex: 1;
    width: 100%;
    .cropper {
      width: auto;
      height: 500px;
    }
  }
  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
    }
  }
}
.footer-btn {
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .scope-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
  }
  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    margin-right: 10px;
  }
}
</style>
